/* eslint-env browser */
exports.onRouteUpdate = ({ location }) => {
  if (window.$) {
    const currentPath = location.pathname;
    window.$('a[href^="https://blog.pregnancycoach.com/blog/"]').each((i, el) => {
      const $el = window.$(el);
      $el.addClass('active');
      if (currentPath === '/blog/') {
        $el.addClass('disabled');
      } else {
        $el.removeClass('disabled');
      }
    });

    window.$('a').each((i, el) => {
      const $el = window.$(el);
      if ($el.hasClass('disabled')) {
        $el.on('click.preventNavigation', false);
      } else {
        $el.off('click.preventNavigation');
      }
    });

    if (window.$.HSCore) {
      window.$.HSCore.init();
      window.$.HSCore.components.HSStickyBlock.init('.js-sticky-block');
    }
  }

  if (window.ga) {
    // Track pageview with google analytics
    window.ga(
      'set',
      'page',
      location.pathname + location.search + location.hash,
    );
    window.ga('send', 'pageview');
  }
};
